import axios from '@axios'

export default {
  namespaced: true,
  state: {
    configData: null,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    // (獲取)儲存資源
    getHomeConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('https://www.real-ro.net/imgData.php')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
