<template>
  <div
    id="news"
    class="content-news-section"
  >
    <b-container class="news-container">
      <b-row>
        <b-col
          cols="12"
          xl="6"
          lg="5"
          class="news-images mb-1 mb-lg-0"
        >
          <carousel
            :items="1"
            :nav="false"
            :dots="true"
            :autoplay="true"
            class="news-images-carousel"
          >
            <div
              v-for="(img, index) in configData.carouselImageData"
              :key="index"
              class="text-center carousel-slide"
            >
              <b-img
                :src="img.image"
                class="carousel-slide-img"
                :alt="`slide-${index+1}`"
              />
            </div>
          </carousel>
        </b-col>

        <b-col
          cols="12"
          xl="6"
          lg="7"
          class="news-lists"
        >
          <div class="warpper">
            <input
              id="one"
              class="radio"
              name="group"
              type="radio"
              checked
            >
            <input
              id="two"
              class="radio"
              name="group"
              type="radio"
            >
            <input
              id="three"
              class="radio"
              name="group"
              type="radio"
            >

            <input
              id="four"
              class="radio"
              name="group"
              type="radio"
            >

            <!-- 商城禮包 -->

            <div class="tabs">
              <label
                id="one-tab"
                class="tab"
                for="one"
              >遊戲公告
                <img
                  :src="require('@/assets/images/items/new.webp')"
                  width="30"
                  style="margin-top: -10px; margin-left: -10px;"
                  alt="最新消息"
                >
              </label>

              <label
                id="two-tab"
                class="tab"
                for="two"
              >疑問解答
              </label>

              <label
                id="three-tab"
                class="tab"
                for="three"
              >攻略分享
              </label>

              <label
                id="four-tab"
                class="tab"
                for="four"
              >商城禮包
                <img
                  :src="require('@/assets/images/items/fire2.webp')"
                  width="20"
                  style="margin-top: -6px"
                  alt="熱門消息"
                >
              </label>
            </div>

            <div class="panels">
              <!-- 遊戲公告 -->
              <div
                id="one-panel"
                class="panel"
              >
                <div>
                  <table
                    v-if="tableData1 && tableData1.length > 0"
                    class="panel-table"
                  >
                    <tr
                      v-for="item in tableData1"
                      :key="item.id"
                      @click="redirectLink(item.href)"
                    >
                      <td class="panel-table-title">
                        <a :href="item.href">{{ item.title ? item.title : '---' }}</a>
                      </td>

                      <td class="panel-table-time">
                        <div class="text-light text-nowrap">
                          {{ item.created_at ? item.created_at : '---' }}
                        </div>
                      </td>

                    </tr>
                  </table>

                  <div
                    v-else-if="!tableData1"
                    class="table-content-nodata"
                  >
                    <!-- <b-img
                      :src="require('@/assets/images/items/loading-2.png')"
                      width="60"
                      alt="Loading"
                    /> -->
                    <loading />
                  </div>

                  <div
                    v-else
                    class="table-content-nodata"
                  >
                    <b-img
                      :src="require('@/assets/images/background/noData-sm.webp')"
                      fluid
                      alt="查無資料"
                    />
                  </div>
                </div>
              </div>

              <!-- 疑問解答 -->
              <div
                id="two-panel"
                class="panel"
              >
                <div>
                  <table
                    v-if="tableData2 && tableData2.length > 0"
                    class="panel-table"
                  >
                    <tr
                      v-for="item in tableData2"
                      :key="item.id"
                      @click="redirectLink(item.href)"
                    >
                      <td class="panel-table-title">
                        <a :href="item.href">{{ item.title ? item.title : '---' }}</a>
                      </td>

                      <td class="panel-table-time">
                        <div class="text-light text-nowrap">
                          {{ item.created_at ? item.created_at : '---' }}
                        </div>
                      </td>

                    </tr>
                  </table>

                  <div
                    v-else-if="!tableData2"
                    class="table-content-nodata"
                  >
                    <!-- <b-img
                      :src="require('@/assets/images/items/loading-2.png')"
                      width="60"
                      alt="Loading"
                    /> -->
                    <loading />
                  </div>

                  <div
                    v-else
                    class="table-content-nodata"
                  >
                    <b-img
                      :src="require('@/assets/images/background/noData-sm.webp')"
                      fluid
                      alt="查無資料"
                    />
                  </div>
                </div>
              </div>

              <!-- 攻略分享 -->
              <div
                id="three-panel"
                class="panel"
              >
                <div>
                  <table
                    v-if="tableData3 && tableData3.length > 0"
                    class="panel-table"
                  >
                    <tr
                      v-for="item in tableData3"
                      :key="item.id"
                      @click="redirectLink(item.href)"
                    >
                      <td class="panel-table-title">
                        <a :href="item.href">{{ item.title ? item.title : '---' }}</a>
                      </td>

                      <td class="panel-table-time">
                        <div class="text-light text-nowrap">
                          {{ item.created_at ? item.created_at : '---' }}
                        </div>
                      </td>

                    </tr>
                  </table>

                  <div
                    v-else-if="!tableData3"
                    class="table-content-nodata"
                  >
                    <!-- <b-img
                      :src="require('@/assets/images/items/loading-2.png')"
                      width="60"
                      alt="Loading"
                    /> -->
                    <loading />
                  </div>

                  <div
                    v-else
                    class="table-content-nodata"
                  >
                    <b-img
                      :src="require('@/assets/images/background/noData-sm.webp')"
                      fluid
                      alt="查無資料"
                    />
                  </div>
                </div>
              </div>

              <!-- 商城禮包 -->
              <div
                id="four-panel"
                class="panel"
              >
                <div>
                  <table
                    v-if="tableData4 && tableData4.length > 0"
                    class="panel-table"
                  >
                    <tr
                      v-for="item in tableData4"
                      :key="item.id"
                      @click="redirectLink(item.href)"
                    >
                      <td class="panel-table-title">
                        <a :href="item.href">{{ item.title ? item.title : '---' }}</a>
                      </td>

                      <td class="panel-table-time">
                        <div class="text-light text-nowrap">
                          {{ item.created_at ? item.created_at : '---' }}
                        </div>
                      </td>

                    </tr>
                  </table>

                  <div
                    v-else-if="!tableData4"
                    class="table-content-nodata"
                  >
                    <!-- <b-img
                      :src="require('@/assets/images/items/loading-2.png')"
                      width="60"
                      alt="Loading"
                    /> -->
                    <loading />
                  </div>

                  <div
                    v-else
                    class="table-content-nodata"
                  >
                    <b-img
                      :src="require('@/assets/images/background/noData-sm.webp')"
                      fluid
                      alt="查無資料"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BContainer, BRow, BCol, BImg,
} from 'bootstrap-vue'
import axios from '@axios'
import carousel from 'vue-owl-carousel'
import loading from '@/layouts/components/Loading.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,

    carousel,
    loading,
  },
  props: {
    configData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // tableData1: [
      //   {
      //     id: 1,
      //     title: '94新年迎二區加開',
      //     href: 'forum.php?mod=viewthread&tid=45',
      //     created_at: '2022/1/12',
      //   },
      //   {
      //     id: 2,
      //     title: '94新年迎二區加開',
      //     href: 'forum.php?mod=viewthread&tid=45',
      //     created_at: '2022/1/12',
      //   },
      //   {
      //     id: 3,
      //     title: '94新年迎二區加開94新年迎二區加開94新年迎二區加開94新年迎二區加開',
      //     href: 'forum.php?mod=viewthread&tid=45',
      //     created_at: '2022/1/12',
      //   },
      //   {
      //     id: 4,
      //     title: '94新年迎二區加開94新年迎二區加開',
      //     href: 'forum.php?mod=viewthread&tid=45',
      //     created_at: '2022/1/12',
      //   },
      //   {
      //     id: 5,
      //     title: '94新年迎二區加開94新年迎二區加開',
      //     href: 'forum.php?mod=viewthread&tid=45',
      //     created_at: '2022/1/12',
      //   },
      //   {
      //     id: 6,
      //     title: '94新年迎二區加開',
      //     href: 'forum.php?mod=viewthread&tid=45',
      //     created_at: '2022/1/12',
      //   },
      // ],
    }
  },
  methods: {
    // (網頁)跳轉
    redirectLink(link) {
      const url = `${window.location.origin}/${link}`
      window.location.assign(url)
    },
  },
  setup(props) {
    const tableData1 = ref(null)
    const tableData2 = ref(null)
    const tableData3 = ref(null)
    const tableData4 = ref(null)

    // 遊戲公告
    axios.get(props.configData.newsLinkAPI1)
      .then(response => {
        if (response.data.success) {
          tableData1.value = response.data.data
        } else tableData1.value = null
      })
      .catch(() => {
        tableData1.value = null
      })

    // 疑問解答
    axios.get(props.configData.newsLinkAPI2)
      .then(response => {
        if (response.data.success) {
          tableData2.value = response.data.data
        } else tableData2.value = null
      })
      .catch(() => {
        tableData2.value = null
      })

    // 攻略分享
    axios.get(props.configData.newsLinkAPI3)
      .then(response => {
        if (response.data.success) {
          tableData3.value = response.data.data
        } else tableData3.value = null
      })
      .catch(() => {
        tableData3.value = null
      })

    // 商城禮包
    axios.get(props.configData.newsLinkAPI4)
      .then(response => {
        if (response.data.success) {
          tableData4.value = response.data.data
        } else tableData4.value = null
      })
      .catch(() => {
        tableData4.value = null
      })

    return {
      tableData1,
      tableData2,
      tableData3,
      tableData4,
    }
  },
}
</script>

<style lang="scss" scoped>
// .content-news-section {
//   margin-top: 40px;
//   // .news-container {
//   //   // padding: 0 5vh;
//   // }
// }

.news-images {
  padding: 0;
  .news-images-carousel {
    background-color: rgba(36, 36, 36, 0.8);
    border-radius: 10px 0 0 10px;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    // img {
    //   min-height: 300px;
    // }
  }
  .carousel-slide {
    min-height: 318px;
    // background-color: red;
    // padding: 10px;
  }
}

.news-lists {
  padding: 0;
  height: 450px;
  .warpper {
    display: flex;
    flex-direction: column;
    background-color: rgba(36, 36, 36, 0.8);
    border-radius: 0 10px 10px 0 ;
    height: 100%;
    padding: 10px;
  }

  .tab {
    cursor: pointer;
    padding: 10px 20px;
    margin: 0px 2px;
    display: inline-block;
    color: #fff;
    font-size: 16px;
  }

  .tab:hover {
    background-color: rgba(255,212,159,0.3);
    border-radius: 10px 10px 0 0;
    cursor: pointer;
  }

  .panels {
    border-top: 2px solid #8f8f8f76;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    overflow: scroll;
    padding: 20px;
    margin-top: -2px;
    position: relative;
  }

  .panel {
    display: none;
    animation: fadein 0.8s;
    .panel-table {
      // background-color: red;
      width:100%;
      color: white;
      font-size: 20px;
      // border-bottom: 2px solid yellow;
      // border-color: gray;
      tr:hover {
        background-color: rgba(255,212,159,0.06);
        color: rgb(255, 145, 0);
        cursor: pointer;
      }
      td {
        display: table-cell;
        padding: .25em .5em;
        padding: 1em !important;
        margin: .5em 1em;
        text-align: left;
        font-size: 16px;
        border-bottom: 2px solid #8f8f8f76;
        // border-bottom: 2px solid yellow;
        a {
          color: rgba(255,255,255);
        }
      }

      .panel-table-time {
        color: rgba(255,255,255,0.35);
      }
    }

    .table-content-nodata {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .radio {
    display: none;
  }

  #one:checked ~ .panels #one-panel,
  #two:checked ~ .panels #two-panel,
  #three:checked ~ .panels #three-panel,
  #four:checked ~ .panels #four-panel {
    display: block;
  }

  #one:checked ~ .tabs #one-tab,
  #two:checked ~ .tabs #two-tab,
  #three:checked ~ .tabs #three-tab,
  #four:checked ~ .tabs #four-tab {
    color: rgb(255, 145, 0);
    border-bottom: 3px solid rgb(255, 145, 0);
    img {
      opacity: 1;
      display: inherit;
    }
  }

  #one ~ .tabs #one-tab {
    img {
      opacity: 0;
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .news-images {
    .news-images-carousel {
      background-color: rgba(36, 36, 36, 0.8);
      padding: 10px 10px 0 10px;
      // padding-bottom: 10px;
      border-radius: 10px;
      img {
        min-height: auto;
        max-height: 450px;
      }
    }
  }

  .news-lists {
    .warpper {
      background-color: rgba(36, 36, 36, 0.8);
      border-radius: 10px;
    }
  }
}

@media (max-width: 576px) {
  .news-lists {
    .tab {
      font-size: 16px;
      img {
        width: 20px;
        margin-top: -5px;
      }
      padding: 8px 10px;
    }

    .panels {
      padding: 0;
    }

    .panel {
      .panel-table {
        td {
          font-size: 12px;
          // padding: 8px 10px;
        }
      }
    }
  }

  .news-images {
    .carousel-slide {
      min-height: 180px;
    }
  }
}

@media (max-width: 1300px) and (min-width: 992px) {
.tab {
    padding: 5px 8px;
    font-size: 14px;
  }
}
</style>
