<template>
  <div v-if="configData">
    <b-container>
      <b-row>
        <b-col
          cols="12"
          xl="5"
        >
          <div class="container fuction-img-col">

            <a
              class="fuction-img"
              :href="configData.OfficialLine"
            >
              <b-img-lazy
                href="#"
                :src="configData.OfficialLineImgHover"
                fluid-grow
                class="mb-1 px-1"
                alt="官方客服LINE"
                @click="redirectLink(configData.OfficialLine)"
              />

              <b-img-lazy
                href="#"
                :src="configData.OfficialLineImg"
                fluid-grow
                class="mb-1 px-1 fuction-img-show"
                alt="官方客服LINE"
                @click="redirectLink(configData.OfficialLine)"
              />

            </a>

            <a
              class="fuction-img"
              :href="configData.OfficialCommunity"
            >
              <b-img-lazy
                :src="configData.OfficialCommunityImgHover"
                fluid-grow
                class="mb-1 px-1"
                alt="遊戲社群討論"
                @click="redirectLink(configData.OfficialCommunity)"
              />

              <b-img-lazy
                :src="configData.OfficialCommunityImg"
                fluid-grow
                class="mb-1 px-1 fuction-img-show"
                alt="遊戲社群討論"
                @click="redirectLink(configData.OfficialCommunity)"
              />
            </a>

            <a
              class="fuction-img"
              :href="configData.OfficialFacebook"
            >
              <b-img-lazy
                :src="configData.OfficialFacebookImgHover"
                fluid-grow
                class="mb-1 px-1"
                alt="臉書粉絲專頁"
                @click="redirectLink(configData.OfficialFacebook)"
              />

              <b-img-lazy
                :src="configData.OfficialFacebookImg"
                fluid-grow
                class="mb-1 px-1 fuction-img-show"
                alt="臉書粉絲專頁"
                @click="redirectLink(configData.OfficialFacebook)"
              />
            </a>
          </div>
        </b-col>

        <b-col
          cols="12"
          xl="7"
          lg="12"
          class="img-card-container"
        >
          <b-row class="p-1 p-sm-3">
            <b-col
              v-for="(item, index) in configData.cardOptions"
              :key="index"
              cols="6"
              lg="4"
            >
              <a
                :href="item.url"
                class="img-card"
              >
                <b-card
                  overlay
                  :img-src="configData.cardBgImg"
                  :img-alt="item.title"
                  @click="redirectLink(item.url)"
                >
                  <b-card-title class="img-card-title m-0">
                    <span>{{ item.title }}</span>
                  </b-card-title>

                  <div class="img-card-doll">
                    <div class="img-card-doll-show">
                      <b-img-lazy
                        :src="item.icon"
                        fluid-grow
                        alt="doll-show"
                      />

                      <b-img-lazy
                        :src="item.icon"
                        fluid-grow
                        class="img-card-doll-hover"
                        alt="doll-hover"
                      />
                    </div>
                  </div>
                </b-card>
              </a>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import {
  BRow, BCol, BContainer, BImgLazy, BCard, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BImgLazy,
    BContainer,
    BCard,
    BCardTitle,
  },
  props: {
    configData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // (網頁)跳轉
    redirectLink(link) {
      if (!link) return
      window.location.assign(link)
    },
  },
}
</script>

<style lang="scss" scoped>
.fuction-img-col {
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: space-between ;
  align-items: center;
  align-content: stretch;
  height: 100%;
}

.fuction-img {
  position: relative;
  flex-basis: 33.3%;
  width: 100%;
  .fuction-img-show {
    position: absolute;
    // background-color: red;
    // transform: translateY(-20px);
    top: 0;
    left: 0;
  }
  :hover {
    cursor: pointer;
    // transition: all 0.5s;
    transform: scale(1.05);
  }
}
.fuction-img img:nth-of-type(2):hover{
  opacity:0;
}

// ----------------------------------------------------------------------------
.img-card {
  position: relative;
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  transition: .2s all;
  // margin-bottom: 40px;
  cursor: pointer;
  align-items: center;
  text-align: center;
  font-size: 13px;
  // padding: 30px;
  .img-card-title {
    position: absolute;
    // width: 100%;
    bottom: 30%;
    top: 52%;
    padding-left: 8%;
    span {
      display: flex;
      font-size: 16px;
      color: white;
      height: 100%;
      text-shadow: black 0.1em 0.1em 0.2em
    }
  }
  // .img-card-doll {
  //   position: absolute;
  //   right: 0;
  //   bottom: 20px;
  //   height: 100%;
  //   padding: 10px;
  //   background-color: red;
  //   img {
  //     height: 120%;
  //     display: flex;
  //     align-items: flex-end;
  //   }
  // }

    .img-card-doll {
      display: flex;
      flex-direction: row;
      justify-content: end;
      height: 100%;

    .img-card-doll-show {
      position: relative;
      img {
        height: 120%;
        right: 0;
        // bottom: -10px;
      }

      .img-card-doll-hover {
        position: absolute;
        // right: -10px;
      }
    }
    }

  .img-card-doll-show img:nth-of-type(2):hover{
    opacity:0;
  }
}

.img-card > span {
  display: block;
  margin: auto;
  max-width: 40px;
}

.img-card-container:hover .img-card {
  // filter: blur(3px);
  opacity: .5;
  transform: scale(.98);
  box-shadow: none;
}

.img-card-container:hover .img-card:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
  color: white;

  .img-card-doll {
    img:nth-of-type(2) {
      animation: x1 1s 0s ease both;
      -moz-animation: x1 1s 0s ease both;
      -webkit-animation: x1 1s 0s ease both;
      -ms-animation: x1 1s 0s ease both;
    }
  }
}

// 文字RWD
@media (min-width: 1200px) {
  .img-card {
    .img-card-title {
      span {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .img-card {
    .img-card-title {
      span {
        font-size: 2.5vmin;
      }
    }
  }
}

@media (max-width: 992px) and (min-width: 576px) {
  .img-card {
    .img-card-title {
      span {
        font-size: 3vmin;
      }
    }
  }
}

@media (max-width: 492px) and (min-width: 300px) {
  .img-card {
    .img-card-title {
      padding-left: 0;
    }
  }
}

@keyframes x1 {
  0% {
      opacity: 1;
      filter: alpha(opacity=100);
      transform: scale3d(1,1,1);
      -moz-transform: scale3d(1,1,1);
      -ms-transform: scale3d(1,1,1);
      -webkit-transform: scale3d(1,1,1);
  }

  100% {
      opacity: 0;
      filter: alpha(opacity=0);
      transform: scale3d(1.5,1.5,1);
      -moz-transform: scale3d(1.5,1.5,1);
      -ms-transform: scale3d(1.5,1.5,1);
      -webkit-transform: scale3d(1.5,1.5,1);
  }
}

@-moz-keyframes x1 {
  0% {
      opacity: 1;
      filter: alpha(opacity=100);
      transform: scale3d(1,1,1);
      -moz-transform: scale3d(1,1,1);
      -ms-transform: scale3d(1,1,1);
      -webkit-transform: scale3d(1,1,1);
  }

  100% {
      opacity: 0;
      filter: alpha(opacity=0);
      transform: scale3d(1.5,1.5,1);
      -moz-transform: scale3d(1.5,1.5,1);
      -ms-transform: scale3d(1.5,1.5,1);
      -webkit-transform: scale3d(1.5,1.5,1);
  }
}

@-webkit-keyframes x1 {
  0% {
      opacity: 1;
      filter: alpha(opacity=100);
      transform: scale3d(1,1,1);
      -moz-transform: scale3d(1,1,1);
      -ms-transform: scale3d(1,1,1);
      -webkit-transform: scale3d(1,1,1);
  }

  100% {
      opacity: 0;
      filter: alpha(opacity=0);
      transform: scale3d(1.5,1.5,1);
      -moz-transform: scale3d(1.5,1.5,1);
      -ms-transform: scale3d(1.5,1.5,1);
      -webkit-transform: scale3d(1.5,1.5,1);
  }
}

@-ms-keyframes x1 {
  0% {
      opacity: 1;
      filter: alpha(opacity=100);
      transform: scale3d(1,1,1);
      -moz-transform: scale3d(1,1,1);
      -ms-transform: scale3d(1,1,1);
      -webkit-transform: scale3d(1,1,1);
  }

  100% {
      opacity: 0;
      filter: alpha(opacity=0);
      transform: scale3d(1.5,1.5,1);
      -moz-transform: scale3d(1.5,1.5,1);
      -ms-transform: scale3d(1.5,1.5,1);
      -webkit-transform: scale3d(1.5,1.5,1);
  }
}
</style>
