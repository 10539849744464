<template>
  <div class="home-download">
    <div class="text-center">
      <div class="waviy">
        <span style="--i:1">下</span>
        <span style="--i:2">載</span>
        <span style="--i:3">專</span>
        <span style="--i:4">區</span>
      </div>
    </div>

    <div class="container">
      <b-row>
        <b-col
          cols="12"
          lg="3"
          sm="6"
        >
          <button
            name="LDPlayer"
            :href="configData.LDPlayer"
            class="download-btn btn4"
            @click="redirectLink(configData.LDPlayer)"
          >
            <a
              :href="configData.LDPlayer"
              class="download-btn-content"
            >
              <img
                :src="require('@/assets/images/items/icons/ldplayer-logo1.webp')"
                class="download-img"
                alt="LD Playe"
              >
              <div class="download-btn-text">
                <span>LD Player</span>
                <b>雷電模擬器用戶</b>
              </div>
            </a>
          </button>
        </b-col>

        <b-col
          cols="12"
          lg="3"
          sm="6"
        >
          <button
            name="NoxPlayer"
            class="download-btn btn4"
            @click="redirectLink(configData.NoxPlayer)"
          >
            <a
              :href="configData.NoxPlayer"
              class="download-btn-content"
            >
              <img
                :src="require('@/assets/images/items/icons/Nox-268x300.webp')"
                class="download-img"
                alt="Nox Player"
              >
              <div class="download-btn-text">
                <span>Nox Player</span>
                <b>夜神模擬器用戶</b>
              </div>
            </a>
          </button>
        </b-col>

        <b-col
          cols="12"
          lg="3"
          sm="6"
        >
          <button
            name="GooglePlay"
            class="download-btn btn4"
            @click="redirectLink(configData.GooglePlay)"
          >
            <a
              :href="configData.GooglePlay"
              class="download-btn-content"
            >
              <img
                :src="require('@/assets/images/items/icons/googleplay.webp')"
                class="download-img"
                alt="Android"
              >
              <div class="download-btn-text">
                <span>Android</span>
                <b>安卓用戶下載</b>
              </div>
            </a>
          </button>
        </b-col>

        <b-col
          cols="12"
          lg="3"
          sm="6"
        >
          <button
            name="AppleIOS"
            class="download-btn btn4"
            @click="redirectLink(configData.AppleIOS)"
          >
            <a
              :href="configData.AppleIOS"
              class="download-btn-content"
            >
              <b-img-lazy
                :src="require('@/assets/images/items/icons/apple.webp')"
                class="download-img"
                alt="IOS"
              />
              <div class="download-btn-text">
                <span>IOS</span>
                <b>蘋果用戶下載</b>
              </div>
            </a>
          </button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BImgLazy,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BImgLazy,
  },
  props: {
    configData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // (網頁)跳轉
    redirectLink(link) {
      if (!link) return
      window.location.assign(link)
    },
  },
}
</script>

<style lang="scss" scoped>
.home-download {
  // margin-top: 10vh;
  .container {
    padding: 0 5vh;
  }
}

.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0,0,0,.2));
  font-size: 30px;
  margin-bottom: 30px;
}
.waviy span {
  // font-family: 'Alfa Slab One', cursive;
  position: relative;
  display: inline-block;
  // color: #fff;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i));
}
@keyframes waviy {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}

.download-btn {
  border: none;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  position: relative;
  outline: none;
  cursor: pointer;
  width: 100%;
  .download-btn-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .download-img {
      margin-right: 10px;
      height: 60px;
      // width: 60px;
    }
    .download-btn-text {
      display: flex;
      flex-flow: column;
      margin-left: 10px;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.48);
      color: white;
      font-size: 28px;
      b {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

.btn1 {
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.582) inset, 0px 0px 10px 0px #E48A3C;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(222, 136, 61, 0.412)), to(rgb(198, 110, 34)));
  background-image: linear-gradient(to bottom, rgba(222, 135, 61, 0.24), rgb(222, 136, 61));
}
.btn1:hover {
  box-shadow: 0px 0px 0px 2px rgba(219, 108, 18, 0.16) inset, 0px 0px 30px 0px rgb(228, 138, 60);
}

.btn2 {
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.582) inset, 0px 0px 10px 0px rgb(56, 69, 154);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(134, 171, 241, 0.412)), to(rgb(56, 69, 154)));
  background-image: linear-gradient(to bottom, rgba(134, 171, 241, 0.412), rgb(56, 95, 154));
}
.btn2:hover {
  box-shadow: 0px 0px 0px 2px rgba(134, 171, 241, 0.412) inset, 0px 0px 30px 0px rgb(56, 69, 154);
}

.btn4 {
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.4) inset, 0px 0px 10px 0px #1d1d1d;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.4)), to(rgb(29, 29, 29)));
  background-image: linear-gradient(to bottom, rgba(134, 171, 241, 0.412), rgb(56, 95, 154));
}
.btn4:hover {
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.55) inset, 0px 0px 30px 0px #1d1d1d;
}

@media (max-width: 1300px) and (min-width: 990px) {
.download-btn {
  padding: 10px 20px 10px 20px;
  margin-bottom: 10px;
  .download-btn-content {
    .download-img {
      margin-right: 10px;
      height: 40px;
      // width: 40px;
    }
    .download-btn-text {
      font-size: 20px;
      b {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}
}

@media (max-width: 768px) and (min-width: 300px) {
.download-btn {
  padding: 10px;
  margin-bottom: 20px;
  .download-btn-content {
    .download-img {
      margin-right: 10px;
      height: 30px;
      // width: 30px;
    }
    .download-btn-text {
      font-size: 16px;
      b {
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}
}

@media (max-width: 300px) {
.download-btn {
  padding: 5px;
  margin-bottom: 20px;
  .download-btn-content {
    .download-img {
      margin-right: 5px;
      height: 20px;
      // width: 20px;
    }
    .download-btn-text {
      font-size: 10px;
      b {
        font-weight: 700;
        font-size: 10px;
      }
    }
  }
}
}

</style>
