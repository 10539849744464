<template>
  <div class="footer-dark">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-7 item text">

            <div class="row">
              <div class="col-lg-3">
                <b-img-lazy
                  :src="configData.footerLogo"
                  class="mb-50"
                  height="50"
                  alt="logo"
                />
              </div>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12">
                    <p>真・仙境手遊版</p>
                    <p>同步韓服最新版 市場唯一 2023年全新力作 長久穩定開服</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 item text">
            <div class="row">
              <div class="col-lg-2">
                <b-img-lazy
                  :src="configData.footerImg"
                  height="46"
                  class="mb-50"
                  alt="rate"
                />
              </div>
              <div class="col-lg-10">
                <p>◆ 本遊戲部份內容涉及暴力(可愛動作)、戀愛交友。</p>
                <p>◆ 本遊戲部份內容需另行支付費用。 ◆ 請避免沉迷遊戲。</p>
              </div>
            </div>
          </div>
        </div>
        <p class="copyright">
          COPYRIGHT  © {{ new Date().getFullYear() }}, All rights Reserved
        </p>

      </div>
    </footer>
  </div>
</template>

<script>
import { BImgLazy } from 'bootstrap-vue'

export default {
  components: {
    BImgLazy,
  },
  props: {
    configData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-dark {
  padding:30px 0 10px 0;
  color:#c4c4c4;
  background-color:#282d32;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align:center;
    padding-bottom:20px;
  }
}

.footer-dark .item.text {
  margin-bottom:36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom:0;
  }
}

.footer-dark .item.text p {
  margin-bottom:0;
}

.footer-dark .copyright {
  text-align:center;
  padding-top:12px;
  font-size:13px;
  margin-bottom:0;
}
</style>
