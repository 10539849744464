<template>
  <b-row class="flex">
    <b-col
      cols="12"
      lg="12"
      md="6"
      sm="6"
      class="section-1 mb-1"
    >
      <div class="item register-donate">
        <a :href="configData.自動贊助連結">
          <vue-load-image>
            <b-img
              slot="image"
              :src="configData.自動贊助Img"
              fluid-grow
              class="link-img"
              alt="自動贊助連結"
            />
            <b-img
              slot="preloader"
              :src="require('@/assets/images/items/line3.webp')"
              fluid-grow
              class="link-img"
              alt="自動贊助連結"
            />
          </vue-load-image>
        </a>
      </div>

    </b-col>

    <b-col
      cols="12"
      lg="12"
      md="6"
      sm="6"
    >
      <div class="item mb-2">
        <div class="mb-1 register-btn">
          <a :href="configData.加入會員">
            <button
              id="signup"
              class="btn-link"
            >
              <h4 class="mb-0">
                加入會員
              </h4>
              <h5 class="mb-0">
                SIGN UP
              </h5>
            </button>
          </a>
        </div>

        <div class="mb-1 register-btn">
          <a :href="configData.獲取邀請碼">
            <button
              id="invitation-code"
              class="btn-link btn-link1"
            >
              <h4 class="mb-0">
                獲取分享邀請碼
              </h4>
              <h5 class="mb-0">
                Invitation Code
              </h5>
            </button>
          </a>
        </div>

        <div class="mb-1 register-btn">
          <a :href="configData.邀請新人">
            <button
              id="invite-newcomers"
              class="btn-link btn-link2"
            >
              <h4 class="mb-0">
                邀請新人
              </h4>
              <h5 class="mb-0">
                Invite Newcomers
              </h5>
            </button>
          </a>
        </div>
      </div>

      <div class="item mb-1">
        <div class="btn-discord">
          <a :href="configData.DISCORD">
            <b-img
              :src="require('@/assets/images/items/discord-logo-white.webp')"
              fluid
              class="p-1"
              alt="DISCORD"
              @click="redirectLink(configData.DISCORD)"
            />
          </a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BImg,
} from 'bootstrap-vue'
import moment from 'moment'
import VueLoadImage from 'vue-load-image'

export default {
  components: {
    BRow,
    BCol,
    BImg,

    VueLoadImage,
  },
  props: {
    configData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    moment,
    // (網頁)跳轉
    redirectLink(link) {
      if (!link) return
      window.location.assign(link)
    },
  },
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: end;
  margin-bottom: 3vh;
}
</style>

<style lang="scss" scoped>
.register-div {
  position: relative;
  min-height: 200px;
  .register-img {
    opacity: 1;
    transition: all 0.5s;
  }
}
.register-div:hover {
  filter: brightness(125%);
  transform: translateY(-5px);
  transition: transform 0.3s;
  cursor: pointer;
}

.register-img-over {
  position: relative;
  min-height: 200px;
}

.register-img-over:hover {
  cursor: pointer;
}

.btn-link {
  --btn-bg-1: #fd8888;
  --btn-bg-2: #f90046;
  --btn-bg-color: white;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  height: 100%;
  transition: 0.8s;
  background-size: 280% auto;
  background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%);
  border: none;
  border-radius: 10px;
  color: var(--btn-bg-color);
  box-shadow: 0px 0px 20px rgba(255, 71, 71, 0.5), 0px 5px 5px -1px rgba(82, 4, 4, 0.25), inset 4px 4px 8px rgba(250, 41, 41, 0.5), inset -4px -4px 8px rgba(48, 2, 2, 0.35);
  // box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35);
  h4 {
    color: var(--btn-bg-color);
    font-size: 14px;
  }
  h5 {
    color: var(--btn-bg-color);
    font-size: 10px;
  }
}

.btn-link:hover {
  background-position: right top;
}

.btn-link:is(:focus, :focus-within,:active) {
  outline: none;
  box-shadow: 0 0 0 3px var(--btn-bg-color), 0 0 0 6px var(--btn-bg-2);
}

.btn-link1 {
  --btn-bg-1: #e9d7a5;
  --btn-bg-2: #f6b829;
  box-shadow: 0px 0px 20px rgba(180, 129, 10, 0.5), 0px 5px 5px -1px rgba(0, 0, 0, 0.25), inset 4px 4px 8px rgba(255, 217, 0, 0.733), inset -4px -4px 8px rgba(48, 2, 2, 0.35);
}

.btn-link2 {
  --btn-bg-1: #9ad6f9;
  --btn-bg-2: #00befd;
  box-shadow: 0px 0px 20px rgba(3, 137, 255, 0.5), 0px 5px 5px -1px rgba(0, 0, 0, 0.25), inset 4px 4px 8px rgba(9, 166, 223, 0.733), inset -4px -4px 8px rgba(48, 2, 2, 0.35);
}

.register-donate {
  min-height: 160px;
}

.link-img:hover {
  cursor: pointer;
  filter: brightness(125%);
  transform: translateY(-5px);
  transition: transform 0.3s;
}

.btn-discord {
  min-height: 60px;
  background-color: rgb(122, 152, 241);
  box-shadow: 0px 0px 20px rgba(4, 4, 4, 0.35);
  border-radius: 10px;
  cursor: pointer;
}
.btn-discord:hover {
  filter: brightness(125%);
  transform: translateY(-5px);
  transition: transform 0.3s;
}

@media (max-width: 992px) {
  .register-donate {
    min-height: 200px;
    width: 100%;
  }
}

@media (max-width: 572px) {
  .item {
    padding: 0 5vw;
  }
  .register-donate {
    min-height: 180px;
  }
}

@media (max-width: 492px) {
  .register-div {
    min-height: 130px;
  }

  .register-img-over {
    min-height: 130px;
  }

  .register-donate {
    min-height: auto;
  }

  .btn-discord {
    min-height: 40px;
  }
}
</style>
