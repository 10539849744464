<template>
  <div class="home-header">
    <div class="header-img-section">

      <div class="header-title">
        <!-- <b-img
          v-if="$store.getters['app/currentBreakPoint'] === 'xs'"
          :src="configData.LOGOsm"
          fluid
          alt="logo-sm"
        />

        <b-img
          v-else
          :src="configData.LOGO"
          fluid
          alt="logo"
        /> -->

        <vue-load-image v-if="$store.getters['app/currentBreakPoint'] === 'xs'">
          <b-img
            slot="image"
            :src="configData.LOGOsm"
            fluid
            alt="logo-sm"
          />
          <b-img
            slot="preloader"
            :src="require('@/assets/images/items/ro-logo.webp')"
            fluid
            alt="logo-sm"
          />
        </vue-load-image>

        <vue-load-image v-else>
          <b-img
            slot="image"
            :src="configData.LOGO"
            fluid
            alt="logo"
          />
          <b-img
            slot="preloader"
            :src="require('@/assets/images/items/Logo.webp')"
            fluid
            alt="logo"
          />
        </vue-load-image>
      </div>

      <div
        v-for="n in 100"
        :key="n"
        class="circle-container"
      >
        <div class="circle" />
      </div>

      <div class="header-more">
        <a
          class="scrolldown"
          href="#news"
          aria-label="Swipe to the latest news block"
          passive="true"
        >
          <div class="chevrons">
            <div class="chevrondown" />
            <div class="chevrondown" />
          </div>
        </a>
      </div>

    </div>

  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import VueLoadImage from 'vue-load-image'

export default {
  components: {
    BImg,
    VueLoadImage,
  },
  props: {
    configData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // (網頁)跳轉
    redirectLink(link) {
      if (!link) return
      window.location.assign(link)
    },
  },
}
</script>

<style lang="scss" scoped>
.home-header {
  // padding-bottom: 30px;
  position: relative;
  .header-img-section {
    // min-height: 50vh;
    // height: 100vh;
    .header-title {
      width: 100vw;
      // // min-height: 50vh;
      padding: 10vh 5vh 1vh 5vh;
      display: flex;
      justify-content: center;
    }

    .circle-container {
      $particleNum: 200;
      $particleColor: hsl(180, 89%, 52%);

      position: absolute;
      top: 0;
      height: 100vh;
      transform: translateY(-10vh);
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      .circle {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        mix-blend-mode: screen;
        background-image: radial-gradient(
          hsl(178, 100%, 80%),
          hsl(67, 100%, 88%) 10%,
          hsla(180, 100%, 80%, 0) 56%
        );

        animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

        @keyframes fade-frames {
          0% {
            opacity: 1;
          }

          50% {
            opacity: 0.7;
          }

          100% {
            opacity: 1;
          }
        }

        @keyframes scale-frames {
          0% {
            transform: scale3d(0.4, 0.4, 1);
          }

          50% {
            transform: scale3d(2.2, 2.2, 1);
          }

          100% {
            transform: scale3d(0.4, 0.4, 1);
          }
        }
      }

      $particleBaseSize: 16;

      @for $i from 1 through $particleNum {
        &:nth-child(#{$i}) {
          $circleSize: random($particleBaseSize);
          width: $circleSize + px;
          height: $circleSize + px;

          $startPositionY: random(10) + 100;
          $framesName: "move-frames-" + $i;
          $moveDuration: 28000 + random(9000) + ms;

          animation-name: #{$framesName};
          animation-duration: $moveDuration;
          animation-delay: random(37000) + ms;

          @keyframes #{$framesName} {
            from {
              transform: translate3d(random(100) + vw, $startPositionY + vh, 0);
            }

            to {
              transform: translate3d(random(100) + vw, -$startPositionY - random(30) + vh, 0);
            }
          }

          .circle {
            animation-delay: random(4000) + ms;
          }
        }
      }
    }

    .header-more{
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-content: end;

      .scrolldown  {
        --color: white;
        --sizeX: 30px;
        --sizeY: 50px;
        position: relative;
        width: var(--sizeX);
        height: var(--sizeY);
        margin-left: var(sizeX / 2);
        border: calc(var(--sizeX) / 10) solid var(--color);
        border-radius: 50px;
        box-sizing: border-box;
        margin-bottom: 16px;
        cursor: pointer;
      }

      .scrolldown::before {
        content: "";
        position: absolute;
        bottom: 30px;
        left: 50%;
        width: 6px;
        height: 6px;
        margin-left: -3px;
        background-color: var(--color);
        border-radius: 100%;
        animation: scrolldown-anim 2s infinite;
        box-sizing: border-box;
        box-shadow: 0px -5px 3px 1px #2a547066;
      }

      @keyframes scrolldown-anim {
        0% {
          opacity: 0;
          height: 6px;
        }

        40% {
          opacity: 1;
          height: 10px;
        }

        80% {
          transform: translate(0, 20px);
          height: 10px;
          opacity: 0;
        }

        100% {
          height: 3px;
          opacity: 0;
        }
      }

      .chevrons {
        padding: 6px 0 0 0;
        margin-left: -3px;
        margin-top: 48px;
        width: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .chevrondown {
        margin-top: -6px;
        position: relative;
        border: solid var(--color);
        border-width: 0 3px 3px 0;
        display: inline-block;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
      }

      .chevrondown:nth-child(odd) {
        animation: pulse54012 500ms ease infinite alternate;
      }

      .chevrondown:nth-child(even) {
        animation: pulse54012 500ms ease infinite alternate 250ms;
      }

      @keyframes pulse54012 {
        from {
          opacity: 0;
        }

        to {
          opacity: 0.5;
        }
      }
      // position: fixed;
      // bottom: 10vh;
    }
  }
}

@media screen and (min-width: 1100px) {
  .header-img-section {
    .header-title {
      height: 600px;
    }
  }
}

@media screen and (max-width: 576px){
  .header-img-section {
    height: 100vh;
    .header-title {
      padding: 30vh 10px !important;
    }

    .header-more {
      visibility: visible !important;
    }
  }
}
</style>
