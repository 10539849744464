<template>
  <div
    v-if="isGetConfig"
    class="home-page"
    :style="{backgroundImage: `url(${require('@/assets/images/background/header.webp')})`}"
  >
    <home-header :config-data="configData" />

    <b-container>
      <!-- style="min-height: 60vh" -->
      <!-- screen and (max-width: 576px) -->
      <b-row class="mb-3">
        <b-col
          cols="12"
          xl="2"
          lg="3"
          order="3"
          order-xl="1"
          :class="$store.getters['app/currentBreakPoint'] === 'md' ? 'px-5' : null"
        >
          <!-- class="d-flex align-items-center justify-content-center" -->
          <home-side
            :config-data="configData"
          />
        </b-col>

        <b-col
          cols="12"
          xl="10"
          lg="9"
          order="2"
          order-xl="2"
          class="mb-2"
        >
          <home-news :config-data="configData" />
        </b-col>
      </b-row>
    </b-container>

    <home-download
      class="mb-2"
      :config-data="configData"
    />
    <home-content :config-data="configData" />
    <home-footer :config-data="configData" />
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import { BContainer, BRow, BCol } from 'bootstrap-vue'

import HomeHeader from './HomeHeader.vue'
import HomeSide from './HomeSide.vue'
// import HomeLogin from './HomeLogin.vue'
// import HomeDonate from './HomeDonate.vue'
import HomeContent from './HomeContent.vue'
import HomeDownload from './HomeDownload.vue'
import HomeNews from './HomeNews.vue'
import HomeFooter from './HomeFooter.vue'

import jsonConfig from '../../../public/config.json'

import store from '@/store'
import useStoreModule from './useStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    HomeHeader,
    // HomeLogin,
    // HomeDonate,
    HomeSide,
    HomeContent,
    HomeDownload,
    HomeNews,
    HomeFooter,
  },
  setup() {
    // 模組註冊
    const HOME_ADMIN_STORE_MODULE_NAME = 'home'

    if (!store.hasModule(HOME_ADMIN_STORE_MODULE_NAME)) store.registerModule(HOME_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(HOME_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(HOME_ADMIN_STORE_MODULE_NAME)
    })

    const isGetConfig = ref(false)
    const configData = ref(null)

    const getHomeConfig = () => {
      store.dispatch('home/getHomeConfig')
        .then(response => {
          if (!response.data.success) {
            configData.value = jsonConfig
            // isGetConfig.value = true
          } else configData.value = response.data.data
          isGetConfig.value = true
        })
        .catch(() => {
          configData.value = jsonConfig
          isGetConfig.value = true
        })
    }

    getHomeConfig()

    return {
      configData,
      isGetConfig,
    }
  },
}
</script>

<style lang="scss" scoped>
.home-page {
  background-size: center;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  background-color: white;
}
</style>
